.DashboardUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 24px;
    font-family: "Poppins", sans-serif;
}

.UserBoxContant {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 43px;
}

.ContantBox {
    width: 20rem;
    border-radius: .5rem;
    box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
    padding: 20px;
}

.userContantImgBox {
    width: 100%;
    height: 12rem;
    border-radius: .5rem;
    overflow: hidden;
}

.userContantTitleBox h4 {
    padding: 0;
    margin: 0;
    color: #636363;
    font-size: 1.3rem;
    font-weight: 600;
}

.userContantTitleBox p {
    color: #636363;
    font-size: 1rem;
    font-weight: 500;
}

.userContantTitleBox button {
    outline: none;
    background-color: #666CCF;
    color: white;
    padding: 0.5rem 1.35rem;
    border-radius: .5rem;
    cursor: pointer;
    transition: .3s ease all;
    border: 1px solid #666CCF;
}

.userContantTitleBox button:hover {
    outline: none;
    border: none;
    background-color: White;
    color: #666CCF;
    padding: 0.5rem 1.35rem;
    border-radius: .5rem;
    cursor: pointer;
    border: 1px solid #666CCF;
}

.UserCardContButton {
    display: flex;
    justify-content: space-between;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    max-width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    cursor: pointer;
}

.modal-body {
    text-align: center;
}

.ProductDiscription {
    display: flex;
    justify-content: space-between;
}

.ProductDiscription p {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c9c8c8;
    border-radius: .5rem;
    color: #636363;
    font-weight: 600;
    font-size: .8rem;
}

.Korzina {
    width: 4rem;
    height: 4rem;
    border-radius: 50rem;
    background-color: #666CCF;
    border: 1px solid #666CCF;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition: .3s ease all;
    cursor: pointer;

}

.Korzina:hover {
    background-color: white;
    border: 1px solid #666CCF;
}

.Korzina:hover .KorzButton {
    color: #666CCF;
}

.Korzina button {
    width: 100%;
    height: 100%;
    outline: none;
    background: none;
    color: white;
    border: none;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.CartDrawer {
    position: fixed;
    top: 0;
    right: -400px;
    width: 400px;
    height: 100%;
    background-color: #fff;
    border-left: 1px solid #ccc;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: right 0.3s ease;
    overflow-y: auto;
    z-index: 1000;
}

.CartDrawer.open {
    right: 0;
}

.CartContent {
    padding: 20px;
}

.CartItem {
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    border-bottom: 1px solid #636363;
    padding-bottom: 10px;
}

@keyframes move-to-cart {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }

    to {
        transform: translate(calc(100vw - 100px), calc(100vh - 100px));
        opacity: 0;
    }
}

.moving-card {
    position: absolute;
    z-index: 9999;
    animation: move-to-cart 1s ease-in-out forwards;
}

.CloseButton {
    margin-bottom: 30px;
}

.CloseButton button {
    color: #666CCF;
    display: block;
    position: absolute;
    top: 10px;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    z-index: 1101;
    display: flex;
    justify-content: center;
    align-items: center;
}


.CartContent {
    padding: 20px;
}

.CartItemImgBox {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CartItemImgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.CartItemText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

}

.CartItemText h4 {
    padding: 0;
    margin: 0;
    text-align: start;
}

.CartItemText p {
    padding: 0;
    margin: 0;
    text-align: start;
}

.SendButtonCartItem {
    width: 91%;
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    gap: 35px;
}

.SendButtonCartItem button {
    background-color: #666CCF;
    color: white;
    border: 1px solid #666CCF;
    border-radius: .5rem;
    font-size: 1rem;
    padding: 1rem 1.5rem;
    transition: .3s all ease;
}

.SendButtonCartItem button:hover {
    color: #666CCF;
    background-color: #fff;
}

@media (max-width:768px) {
    .CartDrawer {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-left: 1px solid #ccc;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
        transition: right 0.3s ease;
        overflow-y: auto;
        z-index: 1000;
    }

    .UserBoxSearch {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .UserBoxSearch form {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .UserBoxSearch form input {
        border: 1px solid #ccc9c9;
        width: 20rem;
        height: 3rem;
        border-radius: .5rem;
        padding: .4rem;
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        color: #636363;
        outline: thin;
    }

    .ContantBox {
        width: 80%;
        border-radius: .5rem;
        box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
        padding: 20px;
    }

    .userContantImgBox {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .userContantImgBox img {
        width: 100%;
        height: 100%;
    }
}


.UserBoxSearch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.UserBoxSearch form {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.UserBoxSearch form input {
    border: 1px solid #ccc9c9;
    width: 20rem;
    height: 3rem;
    border-radius: .5rem;
    padding: .4rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    color: #636363;
    outline: thin;
}

.UserBoxSearch form button {
    border: 2px solid #666CCF;
    width: 5rem;
    height: 3rem;
    border-radius: 0.5rem;
    color: #666CCF;
    font-weight: 600;
    transition: .3s all ease;
    cursor: pointer;
}

.UserBoxSearch form button:hover {
    border: 2px solid #666CCF;
    width: 5rem;
    height: 3rem;
    border-radius: 0.5rem;
    color: white;
    background-color: #666CCF;
    font-weight: 600;
}

.scanner {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
}

#qrCodeContainer {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    overflow: hidden;
}

#qr-shaded-region {
    border-width: 50px !important;
}

video {
    position: absolute;
    width: auto !important;
    min-width: 100%;
    min-height: 100%;
}

.start-button {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    background-color: red;
    border: 0;
    border-radius: 7px;
    font-size: 20px;
    color: #fff;
}

.qr-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 18px;
    color: rgb(255, 255, 255);
    text-align: center;
    word-break: break-all;
}

.close-scanner-button {
    position: absolute;
    top: 1rem;
    right: 0;
}

.close-scanner-button {
    border: none;
    background: none;
    outline: none;
    color: #fff;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.openqrutton {
    width: 4rem;
    height: 4rem;
    border-radius: 50rem;
    background-color: #666CCF;
    border: 1px solid #666CCF;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    left: 10px;
    transition: .3s ease all;
    cursor: pointer;
    overflow: hidden;
}

.openqrutton button {
    width: 100%;
    height: 100%; 
    outline: none;
    background: none;
    color: white;
    border: none;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}