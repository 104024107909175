.Profil {
    width: 94.9vw;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px;
    font-family: "Poppins", sans-serif;
}

.ProfilTitle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ProfilTitle h1 {
    font-size: 1.375rem;
    color: #636578;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.ProfilNav {
    width: 100%;
    height: 20rem;
    border-radius: 1rem;
    box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
    background-image: url(./../../Assets/profile-banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    overflow: hidden;
}

.ProfilNavDown {
    display: flex;
    padding: 20px;
    width: 100%;
    height: 6rem;
    background-color: white;
}

.ProfilLeftRight {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-end;
    align-items: flex-start;
}

.ProfilLeftRight h1 {
    padding: 0;
    margin: 0;
    font-size: 1.375rem;
    color: #636578;
}

.ProfilLeftRight span {
    display: flex;
    gap: 15px;
    color: #636578;
}

.ProfilLeftRight p {
    padding: 0;
    margin: 0;
}

.ProfilNavDownContent {
    display: flex;
    height: 100%;
    gap: 30px;
}

.ProfilNavDownLeft {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.ProfilNavDownRight {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProfilNavDownRight button {
    color: #fff;
    background-color: #666cff;
    font-size: 0.9375rem;
    font-weight: 500;
    padding: 0.45rem 1.375rem;
    outline: none;
    border: none;
    border-radius: .5rem;
}

.ProfilLeftLeft {
    position: relative;
    top: -50px;
    width: 6.5rem;
    height: 7rem;
    border-radius: .5rem;
    background-color: #fff;
    padding: 8px;
}

.ProfilContantTitle {
    margin: 30px 0;
}

.ProfilLeftLeft img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .5rem;
}

.ProfilContantBox {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.ProfilContantBox span {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ProfilContantBox span h4 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #636578;
    font-size: .875rem;
    font-weight: 600;
}

.ProfilContantBox span p {
    padding: 0;
    margin: 0;
    font-size: 0.825rem;
    color: #636578;
    font-weight: 500;

}

.ProfilContant {
    width: 40%;
    border-radius: 1rem;
    box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
    padding: 20px;
    height: 45rem;
}


.ProfilDown {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.ProfilTable {
    width: 100%;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
}


@media (max-width:768px) {
    .Profil {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 20px;
        font-family: "Poppins", sans-serif;
    }

    .ProfilDown {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;
    }

    .ProfilContant {
        width: 100%;
        border-radius: 1rem;
        box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
        padding: 20px;
        height: 45rem;
    }

    .css-wsew38 {
        -webkit-align-items: flex-start;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding: 0.5rem;
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
    }

    .ProfilNavDownContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 30px;
    }

    .ProfilNavDown {
        display: flex;
        padding: 20px;
        width: 100%;
        height: 100%;
        background-color: white;
    }

    .ProfilLeftLeft {
        position: relative;
        top: 0;
        width: 6.5rem;
        height: 7rem;
        border-radius: .5rem;
        background-color: #fff;
        padding: 8px;
    }

    .ProfilNavDownLeft {
        width: 100%;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }

    .ProfilLeftRight span {
        width: 100%;
        display: flex; 
        justify-content: space-between;
        color: #636578;
    }
}