.swiper {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    
  }
  
  .swiper-slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
    
  }
  
  .swiper-slide {
    min-width: 100%;
    box-sizing: border-box;
  }
  
  .swiper-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .swiper-dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #EAEAFF42;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  
  .swiper-dot.active {
    background-color: #ffffff;
  }
  