.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}

.navbar.fixed {
  position: fixed;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  top: 0;
}

.Navmenu {
  display: flex;
  align-items: center;
  gap: 8px;
}

.navmenubutton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.navmenubutton button {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navmenubutton button span {
  color: #636578;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.NavUser {
  display: flex;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.NavUser button {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: none;
  background: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
}

.NavUser button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.SearchMenu {
  width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
}

.SearchMenu label {
  display: flex;
  align-items: center;
  height: 100%;
}

.SearchMenu label span {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #636578;
}

.SearchBox {
  display: flex;
  flex-direction: column;
  position: relative;
}

.search-label input {
  outline: none;
  background: none;
  border: none;
}

.search-form {
  display: flex;
}

.SubmitButton {
  width: 2rem;
  height: 2rem;
  border-radius: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #666CFF1A;
  outline: none;
  cursor: pointer;
  transition: .3s ease all;
}

.SubmitButton:hover {
  background-color: #666CFFA2;
  color: white;
}

.SearchBox label input {
  color: #636578;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.recommendations-list {
  background-color: white;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  gap: 0px;
  position: absolute;
  top: 25px;
  left: 0;
  -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);

}

.recommendations-list li {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 15px;
  color: #636578;
  padding: 0 20px;
  font-family: "Poppins", sans-serif;
  transition: .3s all ease;
  cursor: pointer;
}

.recommendations-list li:hover {
  color: #666CFF;
  background-color: #6365781A;
}

input::placeholder {
  display: flex;
  align-items: center;
  color: #bbbcc4 !important;
}

.dropduwnLang {
  display: none;
}

.ButtonLang {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
}

.ButtonLang.active .dropduwnLang {
  margin-top: 30px;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-width: 7rem;
  background-color: #ffffff;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  font-family: "Poppins", sans-serif;
}

.ButtonLang.active .dropduwnLang button:hover {
  color: #666cff;
  width: 100%;
  background-color: #666cff1A;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.dropduwnUved {
  display: none;
}

.ButtonUved {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.ButtonUved.active .dropduwnUved {
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  min-width: 22rem;
  position: absolute;
  top: 30px;
  right: 0px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  font-family: "Poppins", sans-serif;
  z-index: 10;
}

.UvedTitle {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
}

.UvedTitle p {
  font-size: 15px;
  font-weight: 500;
  color: #636578;
}

.UvedTitle span p {
  border-radius: 50rem !important;
  font-size: 10px;
  background-color: #E9E9FF;
  color: #666CFF;
  padding: 2px 8px;
}

.UserUved {
  display: flex;
  flex-direction: column;
  gap: 0;
  height: 25.75rem;
  overflow-y: auto;
}

.UserUved::-webkit-scrollbar {
  width: 12px;
}

.UserUved::-webkit-scrollbar-track {
  background: white;
}

.UserUved::-webkit-scrollbar-thumb {
  background-color: #63657894;
  border-radius: 20px;
  border: 3px solid white;
}

.UserUvedBox {
  min-height: 73px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d8d8dd;
  border-left: none;
  border-right: none;
}

.UserUvedBox:hover {
  background-color: rgba(128, 128, 128, 0.123);
}

.UvedUserText {
  display: flex;
  flex-direction: column;
}

.UvedUserText p {
  margin: 0;
  padding: 0;
  font-size: 13.6px;
  color: #828393 !important;
  letter-spacing: 1%;
}

.UvedUserText h4 {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #636578;
}

.UvedUserTime {
  display: flex;
  align-items: center;
  justify-content: center;

}

.UvedUserTime p {
  padding: 0;
  margin: 0;
  color: #828393;
  font-size: 12px;
}

.UvedUserAvatars {
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50rem;
}

.UvedUserAvatars img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UvedAll {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
}

.UvedAll button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.47rem 1.375rem;
  background-color: #666CFF;
  border-radius: 8px;
  color: #ffffff;
  text-transform: uppercase;
}

.DropDuwnUserAva {
  display: none;
}

.NavUser.active .DropDuwnUserAva {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  z-index: 10;
  top: 45px;
  right: 0;
  width: 12rem;
  -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
  background-color: #ffffff;
  border-radius: 8px;
}

.UserAvaInfo {
  display: flex;
  align-items: center;
}

.UserAvaInfoImg {
  width: 3.4rem;
  height: 2.5rem;
  overflow: hidden;
  border-radius: 50rem;
  padding: 20px;
}

.UserAvaInfoImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserAvaInfoText {
  display: flex;
  flex-direction: column;
  gap: 5px;
  line-height: 13px;
}

.UserAvaInfoText h4 {
  padding: 0;
  margin: 0;
  color: #828393;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 13.2px;
}

.UserAvaInfoText p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #BBBCC4;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.UserAvaButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.UserAvaButton button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 0 20px;
  border-radius: 8px;
}

.UserAvaButton button span {
  font-size: 18px;
  color: #828393;
  display: flex;
  align-items: center;
}

.UserAvaButton button p {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #828393;
}


.UserAvaButton button:hover {
  background-color: #8283931A;
}

.UserAvaButton a {
  text-decoration: none;
}

.TitleNavbar {
  font-family: "Poppins", sans-serif;
  font-size: 1.35rem;
}

.TitleNavbar p {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 1.35rem;
  color:#636578;
}