.ProductList {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.ProductListTitle h1 {
    padding: 0;
    margin: 0;
    font-size: 1.45rem;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #828393;
}

.ProductListContent {
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    box-shadow: 0 0.125rem 0.625rem 0 rgba(76, 78, 100, 0.22);
    border-radius: 8px;
}

.ProductListBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #bbbbbbaf;
}

.ProductListBoxUp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: "Poppins", sans-serif;
}

.BoxUpLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 7px;
}

.BoxUpLeft h4 {
    padding: 0;
    margin: 0;
    font-size: 1.375rem;
    color: #636578;
}

.BoxUpLeft p {
    padding: 0;
    margin: 0;
    font-size: 0.925rem;
    color: #828393;
    font-weight: 600;
    letter-spacing: 1.3px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.BoxUpLeft p span {
    padding: .1rem .3rem;
    color: #72e128 !important;
    background-color: #eafbdf !important;
    border-radius: 50rem !important;
}

#minus {
    color: #ff4d49 !important;
    background-color: #ffe4e4 !important;
}

#LastProductListBox {
    border: none;
}

.BoxUpRight {
    display: flex;
    justify-content: flex-end;
}

.BoxUpRight span {
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #e9ebee !important;
    color: #636578;
}

.ProductFilterContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 0.125rem 0.625rem 0 rgba(76, 78, 100, 0.22);
    font-family: "Poppins", sans-serif;
    gap: 24px;
    border-radius: .5rem;
}

.ProductFilterTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ProductFilterTitle h1 {
    padding: 0;
    margin: 0;
    font-size: 1.125rem;
    color: #636578;
    font-weight: 500;
}

.ProductFilterButtonBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.ProductFilterButtonBox select {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #636578;
    line-height: 1.3;
    padding: calc(0.5rem - 1px) calc(1.96875rem - 1px) calc(0.5rem - 1px) calc(0.875rem - 1px);
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    outline: 1px auto #d8d8dd;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('./../../../Assets/arrow_down_k4puyso3jpps.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    font-family: "Poppins", sans-serif;
}


.ProductFilterButtonBox select::-ms-expand {
    display: none;
}

.ProductFilterButtonBox select:hover {
    border-color: #888;
}

.ProductFilterButtonBox select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px #666cff;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.ProductFilterButtonBox select option {
    font-weight: normal;
}

*[dir="rtl"] .ProductFilterButtonBox select,
:root:lang(ar) .ProductFilterButtonBox select,
:root:lang(iw) .ProductFilterButtonBox select {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
}

.ProductFilterBottonChtoto {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid #c8c8c9;
}

.ButtonChtotoUp {
    display: flex;
    justify-content: space-between;

}

.ProdFilterKnopki {
    display: flex;
    gap: 15px;
}

.ProdFilterKnopki select {
    padding: calc(0.5rem - 1px) calc(1.96875rem - 1px) calc(0.5rem - 1px) calc(0.875rem - 1px);
    box-sizing: border-box;
    margin: 0;
    outline: 1px auto #d8d8dd;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('./../../../Assets/arrow_down_k4puyso3jpps.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    font-family: "Poppins", sans-serif;
    transition: .2s all ease-in;
}

.ProdFilterKnopki select::-ms-expand {
    display: none;
}

.ProdFilterKnopki select:hover {
    border-color: #888;
}

.ProdFilterKnopki select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px #666cff;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.ButtonExport {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
}

.ButtonExport button {
    position: relative;
    outline: none;
    height: 39px;
    background-color: #E8E9ED;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7.2px 22px;
    gap: 5px;
    border-radius: .5rem;
    color: #6D788D;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    transition: .2s all ease-in;
}

#Exporticon {
    font-size: 18px;
}

.ButtonExport button:hover {
    background-color: #cbcbcefd;
}

.AddProdButton button {
    color: #fff;
    background-color: #666cff;
    padding: 7.2px 22px;
    height: 39px;
    border-color: #666cff;
    border-radius: .5rem;
    font-size: 1.0rem;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
}

.ExportDeopDuwn {
    display: none;
}

.ButtonExport.active .ExportDeopDuwn {
    width: 100%;
    position: absolute;
    top: 44px;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: flex-start;
    box-shadow: 0 0.125rem 0.625rem 0 rgba(76, 78, 100, 0.22);
    border-radius: 0.5rem;
}


.ExportDeopDuwn button {
    background: none;
    outline: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
}

.ExportDeopDuwn button span {
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ExportDeopDuwn button:hover {
    background: none;
    outline: none;
}

.ProdFilterSearch input {
    padding: calc(0.5rem - 1px) calc(1.96875rem - 1px) calc(0.5rem - 1px) calc(0.875rem - 1px);
    border-radius: .5rem;
    outline: none;
    background-color: white;
    border: 1px solid rgb(189, 189, 190);
    font-family: "Poppins", sans-serif;
    transition: .2s all ease-in;
}

.ProdFilterSearch input:focus {

    border-color: #aaa;
    box-shadow: 0 0 1px 3px #666cff;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
}

/* Основной стиль для таблицы */
table {
    width: 100%;
    border-collapse: collapse;
}

td {
    text-align: center;
    padding: 10px;
}

/* Стиль тогл переключателя */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 10px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    top: -0.1875rem;
    left: 0;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0px 2px 1px -1px rgba(76, 78, 100, 0.2), 0px 1px 1px rgba(76, 78, 100, 0.14), 0px 1px 3px rgba(76, 78, 100, 0.12);
}

input:checked+.slider {
    background-color: #666cff;
}

input:checked+.slider:before {
    transform: translateX(16px);
    background-color: #666cff;
}

.ButtonChtotoDuwn {
    margin-top: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

#ProdListGlav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    border-bottom: 1px solid #c2c2c5;
    padding: 15px 20px;
    background-color: #f4f4f6;
}

#ProdListGlav th {
    display: flex;
    align-items: center;
    height: 100%;
    text-transform: uppercase;
    color: #636578;
    font-weight: 500;
}

#PizdecPolniy {
    width: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

#CheckboxProdList,
#CheckboxProdListContent {
    width: 73px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

#CheckboxProdList input[type=checkbox],
#CheckboxProdListContent input[type=checkbox] {
    appearance: none;
    -webkit-appearance: none;
    width: 1.6em;
    height: 1.6em;
    border: 2px solid #cfcfd1;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    outline: none;
    cursor: pointer;
}

#CheckboxProdList input[type=checkbox]:checked,
#CheckboxProdListContent input[type=checkbox]:checked {
    background-color: #cfcfd1;
    border: 2px solid #666cff;
}

#CheckboxProdList input[type=checkbox]::before,
#CheckboxProdListContent input[type=checkbox]::before {
    content: '';
    width: 100%;
    height: 100%;
    background: white;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

#CheckboxProdList input[type=checkbox]:checked::before,
#CheckboxProdListContent input[type=checkbox]:checked::before {
    content: '✓';
    display: block;
    text-align: center;
    font-size: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #666cff;
    color: white;
}

#CheckboxProdListContent input[type=checkbox]:focus {
    border: 2px solid #666cff;
}


#ProductProdList,
#ProductProdListContent {
    width: 395px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

#CategoryProdList,
#CategoryProdListContent {
    width: 196px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

#StockProdList,
#StockProdListContent {
    width: 128px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

#SkuProdList,
#SkuProdListContent {
    width: 108px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

#PriceProdList,
#PriceProdListContent {
    width: 125px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

#QtyProdList,
#QtyProdListContent {
    width: 92px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

#StatusProdList,
#StatusProdListContent {
    width: 149px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

#ActionsProdList,
#ActionsProdListContent {
    width: 123px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

#PizdecPolniy {
    height: 62.56px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d2d2d3;
}

.ProductListContant {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ProductListContantImg {
    width: 1.5rem;
    height: 1.5rem;
    padding: .5rem;
    overflow: hidden;
    background-color: #d1d1d4;
    border-radius: .5rem;
}

.ProductListContantImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ProductContantBoxText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.ProductContantBoxText h4 {
    padding: 0;
    margin: 0;
    color: #636578 !important;
    font-weight: 500 !important;
    font-size: .925rem;
    font-family: "Poppins", sans-serif;
}

.ProductContantBoxText p {
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 85%;
    color: #828393 !important;
    font-weight: 600;

}

.CategoriContant {
    display: flex;
    align-items: center;
    gap: 5px;
}

.CategoriContant span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666cff !important;
    background-color: #E8E9FF;
    padding: .4rem;
    border-radius: 1rem;
}

.CategoriContant p {
    padding: 0;
    margin: 0;
    font-size: .925rem;
    color: #636578 !important;
    font-weight: 500;

}

#SkuProdListContent p {
    color: #828393 !important;
    font-size: .925rem;
    font-weight: 400;
}

#PriceProdListContent p {
    color: #828393 !important;
    font-size: .925rem;
    font-weight: 400;
}

#QtyProdListContent p {
    color: #828393 !important;
    font-size: .925rem;
    font-weight: 400;
}

#StatusProdListContent p {
    color: #fdb528 !important;
    background-color: #fff4df !important;
    border-radius: 50rem !important;
    padding: 0.35em 0.583em;
    font-size: 85%;
}

.LastActionButtons {
    display: flex;
    gap: 10px;
}

.LastActionButtons button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: #828393;
}

.ActionDropDown {
    display: none;

}

.buttonActionGlav {
    position: relative;
    display: flex;
    flex-direction: column;
}

.buttonActionGlav.active .ActionDropDown {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 5px 0;
    top: 20px;
    right: 0;
    width: 160px;
    height: 85px;
    background-color: white;
    align-items: flex-start;
    box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
    border-radius: .5rem;
    z-index: 2;
}

.buttonActionGlav.active .ActionDropDown button {
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 7.76px 16px;
    font-size: 15px;
    font-weight: 400;
}

.buttonActionGlav.active .ActionDropDown button:focus {
    background-color: rgba(102, 108, 255, 0.1);
    color: #666cff;
}

.ButtonChtotoDuwn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
}

.pagination {
    display: flex;
    gap: 10px;
}

#ChototTabe {
    width: 100%;
    height: 30rem;
}

.pagination button {
    cursor: pointer;
    border: none;
}

#Previos {
    border: none;
    background: none;
    font-family: "Poppins", sans-serif;
    font-size: 0.8125rem;
    font-weight: 600;
}

#Next {
    border: none;
    background: none;
    font-family: "Poppins", sans-serif;
    font-size: 0.8125rem;
    font-weight: 600;
}

#Numbers {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    font-size: .8125rem;
    font-weight: 600;
    color: #b3b5b8;
    margin-right: 10px;
    padding: .25rem .70rem;
    border-radius: 50rem;
}

.Numactive {
    padding: .25rem .70rem;
    border-radius: 50rem;
    background-color: #666cff;
    color: #fff;
}