.LeftMenuContainer {
    width: 5rem;
    height: 100vh;
    border: none;
    position: relative;
    -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
    transition: width 0.3s ease;
}

.LeftMenuContainer.active {
    width: 18rem;
}

.Leftmenu {
    position: fixed;
    width: 5rem;
    height: 100vh;
    border: none;
    transition: width 0.3s ease;
}

.Leftmenu.active {
    width: 18rem;
}

.Leftmenu.active .RightAngel {
    display: none;
}

.Leftmenu.active #LeftAngel {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #636578;
    background: none;
    margin-top: 10px;
    transition: width 0.3s ease;
}

.Leftmenu.active #LeftAngel:hover {
    background: none;
}

.Leftmenu.active .TwoQ {
    display: flex;
    font-family: myFont;
    background: rgb(11, 0, 201);
    background: linear-gradient(90deg, rgba(11, 0, 201, 1) 0%, rgba(0, 0, 190, 1) 0%, rgba(0, 212, 255, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: width 0.3s ease;
}

.Leftmenu.active .OneQ {
    display: none;
}

.Leftmenu.active .LeftMenuLogo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: width 0.3s ease;
}

.Leftmenu.active #DashbordbuttonLink {
    display: flex;
    justify-content: flex-start;
}

.LeftMenuLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    transition: width 0.3s ease;
}

#LeftAngel {
    display: none;
}

.LeftMenuLogo h1 {
    padding: 0;
    margin: 0;
    transition: width 0.3s ease;
}


.TwoQ {
    display: none;
}

.ButtonAside {
    width: 40px;
    height: 20px;
    background: none;
    border: none;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    transition: width 0.3s ease;
}

.RightAngel {
    display: flex;
    align-items: center;
    color: #4c4e647a;
    transition: width 0.3s ease;
}

.OneQ {
    display: flex;
    font-family: myFont;
    background: rgb(11, 0, 201);
    background: linear-gradient(90deg, rgba(11, 0, 201, 1) 0%, rgba(0, 0, 190, 1) 0%, rgba(0, 212, 255, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: width 0.3s ease;
}

.Menubutton {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    transition: width 0.3s ease;
}

.Leftmenu.active .Menubutton button {
    border: none;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    transition: width 0.3s ease;
}

.Leftmenu.active .Menubutton button .buttoncont {
    width: 100%;
    display: flex;
    gap: 10px;
    transition: width 0.3s ease;
}

.Leftmenu.active .Menubutton button:hover {
    background-color: #8283931A;
}

.Leftmenu.active .Menubutton button p {
    font-size: 16px;
    font-weight: 500;
    color: #636578;
    font-family: "Poppins", sans-serif;
    transition: width 0.3s ease;
}

.Leftmenu.active .Menubutton button span {
    margin-left: 10px;
    transition: width 0.3s ease;
}

.Leftmenu.active .Menubutton {
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: width 0.3s ease;
}

.Menubutton button {
    border: none;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    transition: width 0.3s ease;
}

.Menubutton a {
    border: none;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    transition: width 0.3s ease;
}

.Menubutton button span {
    font-size: 28px;
    color: #636578;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.3s ease;
}

.Menubutton p {
    display: none;
}

.Menubutton button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: width 0.3s ease;
}

.Menubutton button .buttoncont span {
    transition: .3s all ease;
}

.Menubutton button:hover .buttoncont span {
    color: #666CFF;
}

.Leftmenu.active .Menubutton button:hover .buttoncont span {
    color: #636578;
}

.Leftmenu.active .Menubutton p {
    display: block;
}

.Leftmenu.active #dropdwnaside {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 16px;
    transition: width 0.5s ease;
    padding-right: 5px;

}

#dropdwnaside {
    display: none;
}

.Leftmenu.active .menu-button.active {
    padding: 0;
    margin: none;
    align-items: flex-start;
}

.Leftmenu.active .menu-button.active .ButtonContant {
    background-color: #666CFF;
    color: white;
    border-radius: 8px;
}

.Leftmenu.active .menu-button.active .ButtonContant .buttoncont span,
.Leftmenu.active .menu-button.active .ButtonContant .buttoncont p,
.Leftmenu.active .menu-button.active .ButtonContant span {
    color: white;
}


.Leftmenu.active .menu-button.active:hover {
    background-color: inherit;
}

.Leftmenu.active .ButtonContant {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .3s ease all;
}

.Leftmenu.active #DashButton {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.Leftmenu.active a {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.Leftmenu.active #ButtonAside {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.Leftmenu.active .menu-button {
    padding: 0;
}

.dropdown-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 0;
    margin: 0;
}

.dropdown-content ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    gap: 10px;
    width: 100%;
}

.dropdown-content a {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dropdown-content ul li {
    width: 84%;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #636578;
    font-family: "Poppins", sans-serif;
    list-style: none;
    padding: 0 20px;
    border-radius: 8px;
    transition: .3s all ease;
}


.dropdown-content ul li:hover {
    background-color: #82839328;
}

.Leftmenu.active .menu-button.active #dropdwnaside {
    animation: rotateRight .5s ease forwards;
}

@keyframes rotateRight {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

.Leftmenu.active .menu-button #dropdwnaside {
    animation: rotateBack .5s ease forwards;
}

@keyframes rotateBack {
    from {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.Leftmenu a {
    text-decoration: none;
}

.Leftmenu.active a {
    text-decoration: none;
}

#DashButton a {
    text-decoration: none;
} 