.Dashboard {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px;
}

.SalesContainer {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

@media (min-width:1024px) {
    .SalesBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        padding: 20px;
    }

    .Raiting {
        width: 25%;
        height: 11.699rem;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .Sessions {
        width: 25%;
        height: 11.699rem;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .Slider {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        margin: 0;
    }

    .Visit {
        width: 25%;
        height: 11.699rem;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .MonthSales {
        width: 25%;
        height: 11.699rem;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .WeeklySlider {
        width: 103%;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        overflow: hidden;
    }
}



@media (max-width:1480px) {
    .WeeklySlider {
        width: 125%;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        overflow: hidden;
    }
}

@media (max-width:1440px) {
    .WeeklySlider {
        width: 105%;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
        overflow: hidden;
    }
}

.Slider {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    margin: 0;
}

.SalesBoxUp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.SalesBoxUpLeft {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.SalesBoxUpLeft h1,
.SalesBoxUpLeft p {
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
}


.SalesBoxUpLeft h1 {
    font-size: calc(1.2625rem + 0.15vw);
    font-weight: 600;
    line-height: 38.2px;
    letter-spacing: 1%;
    color: #636578;
}

.SalesBoxUpLeft p {
    font-size: 85%;
    color: #828393;
    font-family: 400;
    display: flex;
    gap: 8px;
    align-items: center;
}

.SalesBoxUpLeft p span {
    display: flex;
    font: normal normal normal 18px / 1 "Material Design Icons";
    color: #72E128;
    font-weight: 600;
    gap: 5px;
}

.SalesBoxUpLeft p span div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.SalesBoxRight {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SalesBoxRight button {
    background: none;
    border: none;
    outline: none;
    font-size: 20px;
    color: #636578;
    cursor: pointer;
    transition: .5s ease all;
    position: relative;
}

.SalesBoxRight button:hover {
    color: black;
}

.SalesBoxDuwn {
    display: flex;
    align-items: center;
    margin: 22px 0;
    gap: 6rem !important;
}

.Custumers,
.Total,
.Transactions,
.Clean {
    display: flex;
    align-items: center;
    gap: 10px;
}

.CustumerLeft {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #e8e9ff;
    color: #666CFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 20px;
}

.TotalLeft {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #FFF4DF;
    color: #FDB528;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 20px;
}

.TransactionsLeft {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #DEF6FE;
    color: #26C6F9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 20px;
}

.CleanLeft {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #defee0;
    color: #26f926;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 20px;
}

.CustumerRight,
.TotalRight,
.TransactionsRight,
.CleanRight {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.CustumerRight h1,
.CustumerRight p,
.TotalRight h1,
.TotalRight p,
.TransactionsRight h1,
.TransactionsRight p,
.CleanRight h1,
.CleanRight p {
    padding: 0;
    margin: 0;

}

.CustumerRight h1,
.TotalRight h1,
.TransactionsRight h1,
.CleanRight h1 {
    font-size: calc(1.2625rem + 0.15vw);
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #636578;
}

.CustumerRight p,
.TotalRight p,
.TransactionsRight p,
.CleanRight p {
    font-size: 85%;
    font-family: "Poppins", sans-serif;
    color: #828393;
}

.DotButtonDrop {
    display: none;
}

.Dotbutton.active .DotButtonDrop {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    right: 0;
    top: 30px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 8px;
    width: 10rem;
    height: auto;
    box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
}

.DotButtonDrop button {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #828393;
}

.RaitingUp h1 {
    font-size: calc(1.2625rem + 0.15vw);
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1%;
    color: #636578;
    padding: 0;
    margin: 0;
}

.RaitingUp span {
    margin: 0;
    padding: 0;
    font-size: 85%;
    color: #828393;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.RaitingDuwn {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 100%;
}

.RaitingUserBox {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.RaitingUserBox h1,
.RaitingUserBox span {
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
}

.RaitingUserBox h1 {
    font-size: 1.375rem;
    color: #636578;
    font-weight: 400;
}

.RaitingUserBox span {
    font-size: 85%;
    color: #26f926;
    font-weight: 500;
}

.SessionUp {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
}

.SessionUp h1 {
    font-size: calc(1.2625rem + 0.15vw);
    font-weight: 600;
    letter-spacing: 1%;
    color: #636578;
    padding: 0;
    margin: 0;
}

.SessionUp span {
    font-size: 85%;
    font-family: "Poppins", sans-serif;
    color: #636578;
    font-weight: 500;
}

.SessionDuwn {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 100%;
}

.SessionUserBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SessionUserBox h1 {
    font-size: 1.375rem;
    color: #636578;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0;
}

.SessionUserBox span {
    font-size: 85%;
    color: #FF4D49;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}



.WeeklyContainer {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.TotalsBox {
    display: flex;
    gap: 2rem;
}

.SliderBoxLeft {
    display: flex;
    flex-direction: column;

}

.SlideContBox {
    display: flex;
    height: 100%;
    background-color: #666cff;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
}


.SliderBoxLeftUp h4 {
    margin: 0;
    padding: 0;
    font-size: 1.125rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #ffffff;
}

.SliderBoxLeftUp p {
    padding: 0;
    margin: 0;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 85%;
    font-weight: 500;
}

.SliderBoxLeftUp p span {
    color: #2cd548;
}

.SliderBoxLeftDuwnTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.SliderBoxLeftDuwnTitle h4 {
    font-size: 0.9375rem;
    color: #ffffff;
    margin: 14px 0;
    font-family: "Poppins", sans-serif;
}

.SliderBoxLeftDuwnBox {
    display: flex;
    width: 60%;
    gap: 2rem;
}

.SliderBoxLeftDuwnBoxLeft,
.SliderBoxLeftDuwnBoxRight {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.SliderBoxLeftContentBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem !important;
    word-wrap: nowrap;
    white-space: nowrap;
}


.SliderBoxLeftDuwnBox span {
    padding: 0.25rem 0.25rem;
    border-radius: 0.375rem;
    min-width: 40px;
    text-align: center;
    background-color: #575cd9;
    font-size: .95rem;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
}

.SliderBoxLeftDuwnBox p {
    padding: 0;
    margin: 0;
    color: white;
    font-size: .95rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    letter-spacing: 1%;
}

.SliderBoxRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 100%;
}

.SliderBoxRightImg {
    width: 100%;
    height: 10rem;
}

.SliderBoxRightImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Visit {
    display: flex;
    flex-direction: column;
}

.VisitTitleBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.VisitTitleBoxOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.VisitTitleBoxOne h4,
.VisitTitleBoxOne p,
.VisitTitleBoxOne span {
    padding: 0;
    margin: 0;
}

.VisitTitleBoxOne h4 {
    font-size: 0.938rem;
    color: #828393 !important;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.VisitTitleBoxOne span {
    color: #72e128 !important;
    font-size: .938rem;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.VisitPodTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.VisitPodTitle h1 {
    padding: 0;
    margin: 0;
    font-size: 1.375rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #636578;
}

.VisitThreeBox {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.VisitThreeOne,
.VisitThreeBoxThree {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.VisitThreeBoxThree {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.VisitThreeOneup,
.VisitThreeBoxUp {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: "Poppins", sans-serif;
}

.VisitThreeBoxUp {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
}

.VisitThreeOneup span {
    font-size: 0.825rem;
    width: 1.563rem;
    height: 1.563rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF4DF;
    color: #FDB528;
    border-radius: 0.5rem !important;
}

.VisitThreeBoxUp span {
    font-size: 0.825rem;
    width: 1.563rem;
    height: 1.563rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e8e9ff;
    color: #666CFF;
    border-radius: 0.5rem !important;
}


.VisitThreeOneup p,
.VisitThreeBoxUp p {
    padding: 0;
    margin: 0;
    font-size: 0.925rem;
    color: #828393;
}

.VisitThreeBoxTwo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: unset;
    text-align: center;
    margin: 1rem 0;
    overflow: hidden;
    white-space: nowrap;
}

.VisitThreeOneDuwn,
.VisitThreeBoxDown {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
}

.VisitThreeOneDuwn {
    align-items: flex-start;
}

.VisitThreeBoxDown {
    align-items: flex-end;
}

.VisitThreeOneDuwn h4,
.VisitThreeBoxDown h4 {
    font-size: 1.375rem;
    padding: 0;
    margin: 0;
    color: #636578;
    font-weight: 500;
}

.VisitThreeOneDuwn p,
.VisitThreeBoxDown p {
    padding: 0;
    margin: 0;
    color: #BBBCC4;
    font-weight: 600;
    font-size: 85%;
}

.VisitThreeBoxTwo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    position: relative;
    margin: 0;
}

.VisitThreeBoxTwo span {
    width: 1px;
    height: 100%;
    background-color: #BBBCC4;
}

.VisitThreeBoxTwo .VisitThreeBoxTwoT {
    position: absolute;
    padding: 0.5rem;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.VisitThreeBoxTwoT p {
    width: 1.1rem;
    height: 1.1rem;
    padding: 0;
    margin: 0;
    background-color: #E9EBEE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-size: 0.6875rem;
    color: #6D788D;
}

.VisitThreeBoxUnder {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: #666cff !important;
    overflow: hidden;
}

.VisitThreeBoxUnder span {
    width: 30%;
    height: 100%;
    background-color: #FDB528;
}

.MonthSalesTitle {
    display: flex;
    justify-content: flex-start;
    font-family: "Poppins", sans-serif;
}

.MonthSalesTitle h4 {
    color: #636578;
    font-size: 1.125rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
    padding-bottom: 20px;

}

.MonthSalesTitlePod {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.MonthSalesTitlePod h4 {
    font-size: .925rem;
    color: #828393;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
}

.MonthSalesTitlePod p {
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.125rem;
    color: #636578;
}

.MonthSalesPng {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.ProdTovar {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: white;
    -webkit-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 31px 0px rgba(34, 60, 80, 0.2);
    border-radius: 8px;
    padding: 20px;
}

.ProdTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ProdTitleLeft {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-family: "Poppins", sans-serif;
}

.ProdTitleLeft h4 {
    padding: 0;
    margin: 0;
    color: #636578;
    font-size: 1.125rem;
    font-weight: 400;
}

.ProdTitleLeft p {
    padding: 0;
    margin: 0;
    color: #828393 !important;
    font-size: 85%;
    font-weight: 600;
}

.ProdTitleRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ProdTitleRight button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    font-size: 1.25rem;
}

.ProdName {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.ProdBox {
    width: 110px !important;
    height: 92px !important;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #eaeaec;
}

.ProdBox span {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    color: #6D788D;
    background-color: #E9EBEE;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProdClass {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.ProdGlavTable {
    width: 100%;
}

table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#zag {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaec !important;
    padding: 10px 0;
}

#zag th {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    color: #636578;
    font-weight: 500;
    font-size: 0.75rem;
}

#yach {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ProdButton {
    display: flex;
    gap: 23px;
}

#ImageContainer {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#ImageGlav {
    width: 40px;
}

#ImageContainer span {
    width: 34px;
    height: 34px;
    border-radius: 0.5rem !important;
    background: #2cd5485e;
}

#ImageContainer span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#Naming,
#ProdGlav {
    width: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;

}

#Naming {
    color: #636578;
    font-weight: 600;
    font-size: 0.9375rem;
}

#StatusGlav,
#Nalichnik {
    width: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

#RevenueGlav,
#Pricing {
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

#ConvertionGlav,
#Procenting {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

.nalichi {
    background-color: #E8E9FF !important;
    color: #666CFF !important;
    border-radius: 50rem !important;
}

.nalichi p {
    padding: 0;
    margin: 0;
    padding: 0 5px;
    font-size: 0.8rem;
}

#Pricing p {
    color: #636578;
    font-size: 0.9375rem;
    font-weight: 600;
}

.Sostoyanie p {
    font-size: 0.9375rem;
    font-weight: 500;
    color: #72e128;
}

#Dlyaskrolla {
    width: 100%;
    height: 10vh;
    overflow: auto;
}

#Dlyaskrolla::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}

#Dlyaskrolla::-webkit-scrollbar-track {
    background: #e8e9ff;
    border-radius: 10px;
}

#Dlyaskrolla::-webkit-scrollbar-thumb {
    background-color: #828393;
    border-radius: 20px;
}

.PeodTitleRightDrop {
    display: none;
}

.icon-button.active {
    position: relative;
}

.icon-button.active .PeodTitleRightDrop {
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 25px;
    right: 0;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(0.5px, 28.5px, 0px);
    box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
    border-radius: 8px;
    padding: 15px 10px;
}

.PeodTitleRightDrop button {
    width: 100%;
    text-align: left;
    font-size: 15px;
    height: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #828393;
    border-radius: 4px;
}

.PeodTitleRightDrop button:focus {
    background-color: #666bff38;
    color: #575cd9;

}

.LstPizdos {
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;
}

.UserContent {
    width: 50%;
    box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
    border-radius: 8px;
    overflow: hidden;
}

#USE {
    display: flex;
    justify-content: space-between;
    width: auto;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-family: "Poppins", sans-serif;
    color: #636578;
    padding: 15px 20px;
    background-color: #ececef;
}

#USE th {
    font-weight: 500;
}

#DlyaskrollaUsera {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}

#UserYach {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#ImageContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}

#ImageContainer {
    width: auto;
    display: flex;
    flex-direction: column;
}

#ImageContainer h4,
#ImageContainer p {
    padding: 0;
    margin: 0;

}

#ImageContainer h4 { 
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #636578;
}

#ImageContainer p {
    font-size: 85%;
    color: #828393;
    font-family: "Poppins", sans-serif;
}

#Email_User p {
    font-size: 15px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #828393;
}

#USE_User {
    width: 200px;
    display: flex;
    justify-content: flex-start;
}

.USerDlyaScrollImg {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    overflow: hidden;
}

.USerDlyaScrollImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.USerDlyaScroll {
    width: 200px;
    display: flex;
    align-items: center;
    gap: 10px;
}

#USE_Email {
    width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

#Email_User {
    width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

#USE_Role {
    width: 120px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

#Role_User {
    width: 120px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Poppins", sans-serif;
}


#Role_User p {
    font-weight: 500;
    color: #828393;

}

.RoleStat {
    display: flex;
    align-items: center;
    gap: 10px;
}

.RoleStat span {
    color: red;
}

#Status_User {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

#Status_User p {
    font-size: 75%;
    padding: 5px 10px;
    background-color: #fff4df !important;
    color: #fdb528 !important;
    border-radius:8px;
}

#DlyaskrollaUsera {
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
}

#DlyaskrollaUsera::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}

#DlyaskrollaUsera::-webkit-scrollbar-track {
    background: #e8e9ff;
    border-radius: 10px;
}

#DlyaskrollaUsera::-webkit-scrollbar-thumb {
    background-color: #828393;
    border-radius: 20px;
}