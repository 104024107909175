.custom-modal .p-dialog-header {
    background: #666CCF;
    color: #fff;
    border: none;
    border-radius: 12px 12px 0 0;
    font-size: 1.25rem;
    font-weight: 600;
}

.custom-modal .p-button-secondary {
    background-color: #555;
    border-color: #555;
    color: #fff;
    border-radius: 6px;
}

.custom-modal .p-button-secondary:hover {
    background-color: #333;
    border-color: #333;
}

.custom-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.product-image-container {
    margin-bottom: 1.5rem;
    text-align: center;
    border-radius: 12px;
    overflow: hidden;
}

.product-image {
    max-width: 100%;
    height: auto;
    border: 4px solid #f4f4f4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.product-details {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.field {
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    width: 100%;
}

.field-label {
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
}

.field-value {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 0.75rem;
    color: #555;
    font-size: 1rem;
}

.cutomModalTitle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
}

.cutomModalTitle h1 {
    padding: 0;
    margin: 0;
}