.Ebeyshee,
.RabEb {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.data-table {
    width: 100%;
}

.data-table .p-datatable-tbody>tr {
    display: flex;
    justify-content: space-between;
}

.data-table .p-datatable-thead>tr {
    display: flex;
    justify-content: space-between;
    background-color: #f8f9fa;
}

.data-table .p-column-title {
    flex: 1;
}

#buttoni-yebanie {
    display: flex;
    gap: 20px;
}


#buttoni-yebanie button {
    background-color: #666CFF;
    border: none;
    border-radius: 0.5rem;
}

#Expotr-Naxuy {
    background-color: #666CFF;
    border: none;
    border-radius: 0.5rem;
}

@media (max-width: 960px) {
    .data-table .p-column-title {
        display: none;
    }

    .data-table .p-column-title:before {
        content: attr(data-header);
        font-weight: bold;
    }

    .data-table td:not(:first-child) {
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 640px) {
    .data-table td {
        display: block;
        width: 100%;
    }

    .data-table .p-datatable-header {
        text-align: center;
    }

    .data-table .p-column-title:before {
        content: attr(data-header);
        font-weight: bold;
    }
}